.small-90, .small-90 label {
    font-size: 90% !important;
    font-weight: normal;
}
.small-85, .small-85 label {
    font-size: 85% !important;
    //font-weight: normal;
}
.small-80, .small-80 label {
    font-size: 80% !important;
    //font-weight: normal;
}
.small-65 {
    font-size: 65%;
    font-weight: normal;
}
.small-60 {
    font-size: 60%;
    font-weight: normal;
}
.small-55 {
    font-size: 55%;
    font-weight: normal;
}
.small-70, .small-70 label {
    font-size: 70% !important;
    font-weight: normal;
}